export default function Styles({ tags }) {
  const t = useI18n()
  return (
    <div className='md:container md:mb-12'>
      <div className='text-[1.2rem] md:text-[1.5rem] font-medium py-10 text-center md:text-left'>發現下一次的髮型靈感</div>
      <div className='grid gap-[2px] md:gap-1 grid-cols-2 md:grid-cols-3'>
        {tags.map(tag => (
          <Box key={ tag.id } tag={ tag } />
        ))}
      </div>
      <div className='text-center py-5'>
        <C.Link href={ h.path.styles() }>
          <C.Button className='w-[20rem]'>
            {t('common:readMore')}
          </C.Button>
        </C.Link>
      </div>
    </div>
  )
}

const Box = ({ tag }) => {
  const renderImage = ({ src, alt, className }) => {
    if (!src) return <div className={ cn('bg-gray-100', className) }></div>
    return <C.Image
      src={ src }
      className={ className }
      alt={ alt }
    />
  }
  return (
    <div className='relative'>
      <div className='flex space-x-[1px] md:space-x-[2px]'>
        <div className='flex-[2_0_0%] m-0'>
          {renderImage({
            src: tag.covers[0]?.firstPhoto.url,
            className: 'pt-[100%]',
            alt: tag.name
          })}
        </div>
        <div className='flex-1 flex flex-col space-y-[1px] md:space-y-[2px]'>
          {renderImage({
            src: tag.covers[1]?.firstPhoto.url,
            className: 'pt-[100%]',
            alt: tag.name
          })}
          {renderImage({
            src: tag.covers[2]?.firstPhoto.url,
            className: 'pt-[100%]',
            alt: tag.name
          })}
        </div>
      </div>
      <C.Link href={ h.path.style({ id: tag.id }) } noUnderline className='absolute inset-0 bg-black/50 text-white flex items-center justify-center text-[1.4rem] opacity-100 hover:backdrop-blur-sm hover:bg-black/70 transition-all'>
        <div className='line-clamp-1'># {tag.name}</div>
      </C.Link>
    </div>
  )

}
