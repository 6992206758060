import Row from 'components/common/stylist/event-row'
import recordsRequest from 'request/user/booking-records'

export default function Stylists({ stylists }) {
  const t = useI18n()
  const { user, ws } = useCurrent()
  const { state, mutate } = useStatic({ userBookingDic: null })

  useEffect(() => {
    updateBookingRecords()
  }, [user])

  useEffect(() => {
    refreshSocket()
    return disconnectSocket
  }, [ws])

  const refreshSocket = () => {
    if (ws?.readyState !== 1) return
    ws.send(JSON.stringify({ stylistEvent: 'connect' }))
  }
  const disconnectSocket = () => {
    if (ws?.readyState !== 1) return
    ws.send(JSON.stringify({ stylistEvent: 'disconnect' }))
  }

  s.UsedQuotaUpdate = ({ stylistId, usedQuota }) => {
    const stylist = stylists.find(r => r.id == stylistId)
    if (!stylist) return
    if (stylist.usedQuota === usedQuota) return
    stylist.usedQuota = usedQuota
    mutate()
  }

  const updateBookingRecords = async() => {
    if (!user) return mutate({ userBookingDic: null })

    const bookingRecords = await recordsRequest()
    if (!bookingRecords.length) return mutate({ userBookingDic: null })

    state.userBookingDic = bookingRecords.reduce((dic, booking) => {
      const { state, stylistUserId } = booking
      dic[stylistUserId] ||= {}
      dic[stylistUserId][state] = true
      return dic
    }, {})
    mutate()
  }

  const updateCallback = (stylistId, usedQuota) => {
    const stylist = stylists.find(r => r.id == stylistId)
    if (!stylist) return
    if (stylist.usedQuota === usedQuota) return
    stylist.usedQuota = usedQuota
    mutate()
  }

  return (
    <div className='mt-2 mb-5'>
      <div className='md:container text-[1.2rem] md:text-[1.5rem] font-medium pt-10 pb-6 text-center md:text-left'>限量體驗活動</div>
      {stylists.map(stylist => (
        <Row
          key={ stylist.id }
          stylist={ stylist }
          userBookingDic={ state.userBookingDic }
          bookingSuccessCallback={ updateBookingRecords }
          updateCallback={ updateCallback }
          serviceCategory='haircut'
        />
      ))}
      <div className='text-center pb-5'>
        <C.Link href={ h.path.stylistEvents() }>
          <C.Button className='w-[20rem]'>
            {t('common:readMore')}
          </C.Button>
        </C.Link>
      </div>
    </div>
  )
}
