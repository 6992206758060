import Hero from './hero'
import Events from './events'
import Styles from './styles'
import Stylists from './stylists'
import Faq from 'components/common/intro/faq'

export default function Root({ initData }) {

  return (
    <div>
      <Hero />
      <Events />
      <Stylists stylists={ initData.stylists } />
      <Styles tags={ initData.tags } />
      <div className='bg-gray-50'>
        <Faq />
      </div>
    </div>
  )
}
